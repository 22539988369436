<template>
  <div class="results section section--body">
    <h3>Результати роботи</h3>

    <div class="filters">
      <ul>
        <li v-for="item in cats" :key="item.value" :class="{ active: isCat == item.name }" @click="isCat = item.name">
          {{ item.name }}
        </li>
      </ul>
    </div>

    <Loader v-if="isLoad" />

    <div class="cards" v-else>
      <div v-for="(item, index) in last.slice().reverse()" :key="index">
        <Card :data="item" />
      </div>
    </div>

    <div class="load">
      <!-- <div class="button button--outline">Завантажити ще</div>   -->
    </div>
  </div>
</template>
<script>
  import Card from "../components/global/Card.vue";
  import Loader from "../components/global/Loader.vue";
  import data from "../data/links.js";
  import axios from "axios";

  import { ref, watch } from "vue";

  export default {
    components: {
      Card,
      Loader,
    },
    setup() {
      const isLoad = ref(true);
      const isCat = ref("Всі");
      const last = ref([]);

      const cats = [
        { name: "Всі", value: 0 },
        { name: "Ремон дорожньої інфраструктури", value: 1 },
        { name: "Водопостачання", value: 2 },
        { name: "Теплопостачання", value: 3 },
        { name: "Житлове господарство", value: 4 },
        { name: "Благоустрій", value: 5 },
      ];

      const fetchLast = async (cat) => {
        isLoad.value = true;

        try {
          let get = await axios.get(data.dates),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase().replace(/\s/g, "")] = row[i];
            });

            formated.push(formattedRow);
          }

          if (cat == "Всі") {
            last.value = formated.filter((e) => e.погоджено != "");
          } else {
            last.value = formated.filter((e) => e.погоджено != "" && e.категорія == cat);
          }
          console.log(last.value);

          setTimeout(() => {
            isLoad.value = false;
          }, 500);
        } catch (e) {
          console.log(e);
        }
      };

      watch(
        () => isCat.value,
        () => {
          fetchLast(isCat.value);
        }
      );

      fetchLast("Всі");

      return { isLoad, isCat, last, cats };
    },
  };
</script>
<style lang="scss" scoped>
  .results {
    display: grid;

    min-height: 500px;

    .filters {
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0.2rem 0.5rem;
        margin: 1rem 0;

        li {
          margin: 0 0.5rem;
          cursor: pointer;
          color: $dark-grey;
          flex-shrink: 0;

          &:hover {
            color: #333;
          }

          &.active {
            color: $acs;
          }
        }
      }
    }

    .load {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
  }
</style>
